<template>
  <ol class="breadcrumb">
    <li v-for="(part, i) of fullParts" :key="part.name" class="parts">
      <NuxtLink :href="`${part.url}`" class="part">{{ part.name }}</NuxtLink>
      <div v-if="i < fullParts.length - 1" class="separator">&gt;</div>
    </li>
  </ol>
</template>

<script lang="ts" setup>
import { BREADCRUMB } from '@/lib/constants';
import type { ListItem } from 'schema-dts';
const { parts } = defineProps<{ parts: { name: string; url: string }[] }>();
const fullParts = ref([
  { name: BREADCRUMB.home, url: '/' },
  ...parts.map(part => ({ ...part, name: part.name }))
]);

const itemListElementJSON: ListItem[] = fullParts.value.map((part, index) => ({
  '@type': 'ListItem',
  position: index + 1,
  item: {
    '@id': part.url,
    name: part.name
  }
}));

// json+ld for breadcrumb
useJsonld({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: itemListElementJSON
});
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/colors.scss';
@use '$/mouse.scss';

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;

  margin: 24px 40px 0;

  list-style-type: none;

  @include breakpoints.mobile() {
    margin: 0;
    padding: 12px 16px;

    font-size: 12px;
    line-height: 14px;

    background-color: colors.$grey-100;
  }

  .parts {
    display: flex;
    gap: 8px;

    .part {
      @include mouse.clickable-lightness-hover();

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
}
</style>
